import styled from 'styled-components';
import tw from 'tailwind.macro';

export const TitleSection = styled.div`
  ${tw`flex flex-col w-full  `};
`;

export const Title = styled.h2`
  ${tw`uppercase mb-8 text-lg font-bold w-full text-left`};
  ${({ center }) => center && tw`text-center`};
`;

export const SubTitle = styled.h4`
  ${tw`text-xs text-green-500 w-full text-left`};
  ${({ center }) => center && tw`text-center`};
`;

export const Separator = styled.h2``;
